import Button from '@/components/global/button';
import Icon, { IconMap, IconProps } from '@/components/global/icon';
import LoadingIcon, { type LoadingIconProps } from '@/components/ui/loading-icon';
import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import cn from '@/lib/cn';
import { type AllTrackingPropertiesTypes, type EventType } from '@/types/tracking';

interface IconButtonTrackingProps {
    trackingProperties?: Omit<AllTrackingPropertiesTypes, 'iconType'>;
    eventType: EventType;
}

interface IconButtonProps {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
    onMouseOver?: React.MouseEventHandler<HTMLButtonElement>;
    onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>;
    onTouchEnd?: React.TouchEventHandler<HTMLButtonElement>;
    onTouchStart?: React.TouchEventHandler<HTMLButtonElement>;
    iconType: keyof typeof IconMap;
    className?: string;
    title?: string;
    tabIndex?: number;
    iconProps?: {
        className?: string;
        iconClassName?: string;
        size?: IconProps['size'];
        fill?: IconProps['fill'];
        stroke?: IconProps['stroke'];
        color?: IconProps['color'];
    };
    tracking?: IconButtonTrackingProps;
    isDisabled?: boolean;
}

const IconButton = ({
    onClick,
    onMouseEnter,
    onMouseOver,
    onMouseLeave,
    onTouchEnd,
    onTouchStart,
    iconType,
    className,
    iconProps,
    tabIndex,
    title,
    tracking = {} as IconButtonTrackingProps,
    isDisabled = false,
}: IconButtonProps) => {
    return (
        <Button
            isDisabled={isDisabled}
            tabIndex={tabIndex}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            type="action"
            color="transparent"
            title={title}
            tracking={{
                eventType: tracking.eventType,
                trackingProperties: tracking?.trackingProperties || ({} as AllTrackingPropertiesTypes),
            }}
            className={cn(
                'hover:opacity-70 transition-opacity text-transparent p-0 bg-transparent border-none',
                className
            )}
        >
            <Icon
                color="inherit"
                className="text-analyst-purple border-0"
                type={iconType}
                stroke="none"
                size="xxl"
                {...iconProps}
            />
        </Button>
    );
};

type IconSubmitButtonProps = Omit<IconButtonProps, 'onTouchEnd' | 'onClick'> & {
    onClick: (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => void;
    isSubmitting?: boolean;
    loadingIconProps?: LoadingIconProps;
};

export const IconSubmitButton = ({
    onClick,
    iconType,
    className,
    iconProps,
    tabIndex,
    title,
    tracking = {} as IconButtonTrackingProps,
    isSubmitting = false,
    loadingIconProps,
}: IconSubmitButtonProps) => {
    const { eventTypes, trackButtonClick } = usePosthogTracking();
    const submitHandler = (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) => {
        onClick(e);
        trackButtonClick({
            eventType: tracking.eventType || eventTypes.SUBMIT_BUTTON_CLICK,
            trackingProperties: tracking?.trackingProperties || ({} as AllTrackingPropertiesTypes),
        });
    };

    return (
        <button
            tabIndex={tabIndex}
            type="submit"
            title={title}
            onClick={submitHandler}
            className={cn(
                'hover:opacity-70 transition-opacity text-transparent p-0 bg-transparent border-none',
                className
            )}
        >
            {isSubmitting ? (
                <LoadingIcon {...loadingIconProps} />
            ) : (
                <Icon
                    color="inherit"
                    className="text-analyst-purple border-0"
                    type={iconType}
                    stroke="none"
                    size="xxl"
                    {...iconProps}
                />
            )}
        </button>
    );
};

export default IconButton;
