'use client';

import NextLink, { LinkProps } from 'next/link';
import { useRouter } from 'next/navigation';
import React, { forwardRef, ReactNode, Ref } from 'react';

import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import cn from '@/lib/cn';
import { type NavigationLinkClickTrackingProperties } from '@/types/tracking';
import { EventTypes } from '@/types/tracking';

const defaultStyles = cn(
    'group transition-colors text-brand-blue-yves no-underline hover:text-analyst-dark-lavender hover:cursor-pointer'
);

export type NavigationLinkProps = LinkProps & {
    target?: string;
    children: ReactNode;
    id?: string;
    className?: string;
    tabIndex?: number;
    title?: string;
    rel?: string;
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
    onMouseOver?: React.MouseEventHandler<HTMLAnchorElement>;
    onMouseLeave?: React.MouseEventHandler<HTMLAnchorElement>;
    onTouchEnd?: React.TouchEventHandler<HTMLAnchorElement>;
    onTouchStart?: React.TouchEventHandler<HTMLAnchorElement>;
    trackingEventType?: EventTypes;
    trackingProperties?: Omit<NavigationLinkClickTrackingProperties, 'href'>;
};

export const NavigationLink = forwardRef(function NavigationLink(
    {
        children,
        className,
        trackingProperties = {} as Omit<NavigationLinkClickTrackingProperties, 'component' | 'href'>,
        trackingEventType,
        ...passThroughProps
    }: NavigationLinkProps,
    ref: Ref<HTMLAnchorElement>
) {
    const { eventTypes, components, trackNavigationLinkClick } = usePosthogTracking();
    const trackingAction = () => {
        trackNavigationLinkClick({
            eventType: trackingEventType || eventTypes.INTERNAL_LINK_CLICK,
            trackingProperties: {
                ...trackingProperties,
                component:
                    trackingProperties.component || passThroughProps.target === '_blank'
                        ? components.EXTERNAL_NAVIGATION_LINK
                        : components.INTERNAL_NAVIGATION_LINK,
                href: passThroughProps.href as string,
            },
        });
    };

    return (
        <NextLink
            ref={ref}
            className={cn(defaultStyles, className)}
            {...passThroughProps}
            onClick={trackingAction}
        >
            {children}
        </NextLink>
    );
});

type ActionLinkProps = {
    id?: string;
    children: ReactNode;
    className?: string;
    onClick: React.MouseEventHandler<HTMLAnchorElement>;
    onTouchEnd?: React.TouchEventHandler<HTMLAnchorElement>;
    onTouchStart?: React.TouchEventHandler<HTMLAnchorElement>;
    onMouseOver?: React.MouseEventHandler<HTMLAnchorElement>;
    onMouseLeave?: React.MouseEventHandler<HTMLAnchorElement>;
    title?: string;
    trackingEventType?: EventTypes;
    trackingProperties?: Omit<NavigationLinkClickTrackingProperties, 'href'>;
    openInNewTab?: boolean;
    path?: string;
    isDisabled?: boolean;
};

export const ActionLink = ({
    id,
    children,
    className,
    onClick,
    title,
    trackingProperties = {} as Omit<NavigationLinkClickTrackingProperties, 'component' | 'href'>,
    trackingEventType,
    path,
    openInNewTab = false,
    isDisabled = false,
    ...passThroughProps
}: ActionLinkProps) => {
    const { push } = useRouter();
    const { eventTypes, components, trackNavigationLinkClick } = usePosthogTracking();
    const isNavigationLink = !!path;
    const trackingAction = () => {
        trackNavigationLinkClick({
            eventType:
                trackingEventType || (openInNewTab && isNavigationLink)
                    ? eventTypes.EXTERNAL_LINK_CLICK
                    : isNavigationLink
                    ? eventTypes.INTERNAL_LINK_CLICK
                    : eventTypes.ACTION_LINK_CLICK,
            trackingProperties: {
                ...trackingProperties,
                component:
                    openInNewTab && isNavigationLink
                        ? components.EXTERNAL_NAVIGATION_LINK
                        : isNavigationLink
                        ? components.INTERNAL_NAVIGATION_LINK
                        : components.ACTION_LINK,
                href: path as string,
            },
        });
    };
    const onClickAction = (e: React.MouseEvent<HTMLAnchorElement>) => {
        trackingAction();
        onClick(e);
        openInNewTab && window.open(path, '_blank');
        !openInNewTab && path && push(path);
    };

    return (
        <a
            className={cn(defaultStyles, className, {
                'text-brand-gray-dark hover:text-brand-gray-dark hover:cursor-default': isDisabled,
            })}
            {...{ id, onClick: onClickAction, title }}
            {...passThroughProps}
        >
            {children}
        </a>
    );
};
